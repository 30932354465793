<template>
    <div class="text-center">
        <v-dialog
          v-model="component.invalidDateDialog"
          width="380"
        >
    
          <v-card class="text-center">
            <v-card-text class="text-center, text-h6 , pt-3">
            Período selecionado Inválido
            </v-card-text>
            <v-card-actions>
            <v-col class="align-end" >
              <v-btn color="success"  @click="component.invalidDateDialog = false">Fechar Mensagem</v-btn>
            </v-col>
            </v-card-actions>
          
          </v-card>
        </v-dialog>
      </div>
    </template>
    <script>
    
    export default {
        props: {
        component: Object(),
      },
      data: () => ({
      }),
    };
    </script>